exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-dlaczego-my-js": () => import("./../../../src/pages/dlaczego-my.js" /* webpackChunkName: "component---src-pages-dlaczego-my-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-obsluga-kadrowo-placowa-js": () => import("./../../../src/pages/obsluga-kadrowo-placowa.js" /* webpackChunkName: "component---src-pages-obsluga-kadrowo-placowa-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-rejestracja-pojazdow-js": () => import("./../../../src/pages/rejestracja-pojazdow.js" /* webpackChunkName: "component---src-pages-rejestracja-pojazdow-js" */),
  "component---src-pages-ubezpieczenia-js": () => import("./../../../src/pages/ubezpieczenia.js" /* webpackChunkName: "component---src-pages-ubezpieczenia-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-uslugi-ksiegowe-js": () => import("./../../../src/pages/uslugi-ksiegowe.js" /* webpackChunkName: "component---src-pages-uslugi-ksiegowe-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

